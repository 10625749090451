import axiosConfig from "./config/axiosConfig";
import { REFRESH_TOKEN_TYPE } from "./constant/asynActionsType";
import { refreshToken } from "./redux/features/auth/authActions";
import { store } from "./redux/store";
import {
  getAccessToken,
  getRefreshToken,
  logout,
  setAccessToken,
} from "./utils/tokenStorage";

// Request interceptor
axiosConfig.interceptors.request.use(
  (config) => {
    const token = getAccessToken();

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response interceptor
axiosConfig.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    console.log({ error });
    // Handle refresh token logic
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshTokenValue = getRefreshToken();
        if (refreshTokenValue) {
          await store
            .dispatch(refreshToken({ refresh: refreshTokenValue }))
            .then((e) => {
              if (e.type === `${REFRESH_TOKEN_TYPE}/fulfilled`) {
                setAccessToken(e.payload.token);
                originalRequest.headers.Authorization = `Bearer ${e.payload.token}`;
              } else {
                logout();
              }
            });
          return axiosConfig(originalRequest);
        } else {
          logout();
        }
      } catch (refreshError) {
        logout();
        // Handle refresh token failure (e.g., logout user)
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);
