import { Checkbox, Chip, IconButton, Tooltip } from "@mui/material";
import { active, inactive, pending, tooltTitle } from "../../constant/constant";
import { createColumnHelper } from "@tanstack/react-table";
import { black } from "../../constant/color";
import { getStatusStyle } from "../../assets/styles/chipStyles";
import { DeleteIcon, EditIcon, VisibilityIcon } from "../../components/svg";
import { checkBoxStyle } from "../../assets/styles/checkboxStyle";
import { capitalizeAndFormat, dateFormat } from "../../utils/helper";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import { useSelector } from "react-redux";
import { modules, permissionsKey } from "../data";
import { checkPermissions } from "../../utils/checkPermission";
import HistoryIcon from "@mui/icons-material/History";

const withSorting = (columns) => {
  return columns.map((column) => ({
    ...column,
    enableSorting: !["actions", "status"].includes(column.accessorKey), // Disable sorting for action columns
    sortingFn: "alphanumeric",
  }));
};

const columnHelper = createColumnHelper();

const RenderUsersActions = ({ row, table }) => {
  const { user } = useSelector((state) => state.auth);

  const userPermission = user?.permissions[modules.users];
  const { u, d } = permissionsKey;

  return (
    <>
      {row?.original?.status === pending && (
        <Tooltip title={tooltTitle.resend_activ_link} {...tooltiPlacemetpProps}>
          <IconButton
            sx={{ py: 0, px: 0.5 }}
            onClick={() => table.options.meta.sendemail(row?.original)}
          >
            <ForwardToInboxIcon
              sx={{ color: black, fontSize: 20 }}
              height="15"
              width="15"
            />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={tooltTitle.view} {...tooltiPlacemetpProps}>
        <IconButton
          sx={{ py: 0, px: 0.5 }}
          onClick={() => table.options.meta.view(row?.original)}
        >
          <VisibilityIcon fillColor={black} height="18" width="18" />
        </IconButton>
      </Tooltip>
      {checkPermissions(userPermission, [u]) && (
        <Tooltip title={tooltTitle.edit} {...tooltiPlacemetpProps}>
          <IconButton
            sx={{ py: 0, px: 0.5 }}
            onClick={() => table.options.meta.edit(row?.original)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
      {checkPermissions(userPermission, [d]) && (
        <>
          <Tooltip title={tooltTitle.delete} {...tooltiPlacemetpProps}>
            <IconButton
              sx={{ py: 0, px: 0.5 }}
              onClick={() => table.options.meta.delete(row?.original)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
      <Tooltip title={tooltTitle.history} {...tooltiPlacemetpProps}>
        <IconButton
          sx={{ py: 0, px: 0.5 }}
          onClick={() => table.options.meta.history(row?.original)}
        >
          <HistoryIcon sx={{ color: "var(--primary-color)", fontSize: 20 }} />
        </IconButton>
      </Tooltip>
    </>
  );
};

const RenderRolesActions = ({ row, table }) => {
  const { user } = useSelector((state) => state.auth);

  const rolesPermission = user?.permissions[modules.roles];
  const { u, d } = permissionsKey;
  return (
    <>
      <Tooltip title="View" placement="left">
        <IconButton
          sx={{ py: 0, px: 0.5 }}
          onClick={() => table.options.meta.view(row?.original)}
        >
          <VisibilityIcon fillColor={black} height="18" width="18" />
        </IconButton>
      </Tooltip>
      {checkPermissions(rolesPermission, [u]) && (
        <Tooltip title="Edit" placement="left">
          <IconButton
            sx={{ py: 0, px: 0.5 }}
            onClick={() => table.options.meta.edit(row?.original)}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
      {checkPermissions(rolesPermission, [d]) && (
        <>
          <Tooltip title="Delete" placement="left">
            <IconButton
              sx={{ py: 0, px: 0.5 }}
              onClick={() => table.options.meta.delete(row?.original)}
            >
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
      <Tooltip title={tooltTitle.history} {...tooltiPlacemetpProps}>
        <IconButton
          sx={{ py: 0, px: 0.5 }}
          onClick={() => table.options.meta.history(row?.original)}
        >
          <HistoryIcon sx={{ color: "var(--primary-color)", fontSize: 20 }} />
        </IconButton>
      </Tooltip>
    </>
  );
};

export const tooltiPlacemetpProps = {
  placement: "left",
};

const matchStatusStyple = {
  [active]: {
    backgroundColor: "var(--seagreen-color)",
    border: "1px solid var(--emerald-color)",
  },

  [pending]: {
    backgroundColor: "var(--tumeric-color)",
    border: "1px solid var(--gold-color)",
  },
  [inactive]: {
    backgroundColor: "var(--copper-color)",
    border: "1px solid var(--brown-color)",
  },
};

// users
export const columnData = withSorting([
  columnHelper.accessor("name", {
    id: "name",
    header: "Name",
    isVisible: true,
    // cell: (info) => info.getValue(),
    // footer: (info) => info.column.id,
  }),
  columnHelper.accessor("department", {
    id: "department",
    header: "Department",
    isVisible: true,
    cell: ({ row }) => {
      const department = row?.original?.department;
      return <>{department || "-"}</>;
    },
  }),
  columnHelper.accessor("designation", {
    id: "designation",
    header: "Designation",
    isVisible: true,
    cell: ({ row }) => {
      const designation = row?.original?.designation;
      return <>{designation || "-"}</>;
    },
  }),
  columnHelper.accessor("email", {
    id: "email",
    header: "Email",
    isVisible: true,
  }),
  columnHelper.accessor("mobile_number", {
    id: "mobile_number",
    header: "Mobile",
    isVisible: true,
    cell: ({ row }) => {
      const mobile_number = row?.original?.mobile_number;
      return <>{mobile_number || "-"}</>;
    },
  }),
  columnHelper.accessor("status", {
    id: "status",
    header: "Status",
    isVisible: true,
    cell: ({ row }) => {
      const status = row?.original?.status;
      return (
        <Chip label={status} sx={getStatusStyle(matchStatusStyple[status])} />
      );
    },
  }),
  columnHelper.accessor("roles", {
    id: "roles",
    header: "Access",
    isVisible: true,
  }),
  columnHelper.accessor("last_login", {
    id: "last_login",
    header: "Last Activity",
    isVisible: true,
    cell: ({ row }) => {
      const last_login = row?.original?.last_login;
      return <>{dateFormat(last_login)}</>;
    },
  }),
  columnHelper.accessor("actions", {
    id: "actions",
    header: "Actions",
    isVisible: true,
    cell: ({ row, table }) => <RenderUsersActions row={row} table={table} />,
  }),
]);

// roles
export const rolesColumnData = withSorting([
  columnHelper.accessor("role_name", {
    id: "role_name",
    header: "Name",
    // cell: (info) => info.getValue(),
    // footer: (info) => info.column.id,
  }),
  columnHelper.accessor("profile_type", {
    id: "profile_type",
    header: "Profile Type",
  }),

  columnHelper.accessor("user_count", {
    id: "user_count",
    header: "Users",
  }),
  columnHelper.accessor("created_by", {
    id: "created_by",
    header: "Created By",
  }),
  columnHelper.accessor("updated_by", {
    id: "updated_by",
    header: "Updated By",
  }),
  columnHelper.accessor("actions", {
    id: "actions",
    header: "Actions",
    cell: ({ row, table }) => <RenderRolesActions row={row} table={table} />,
  }),
]);

const renderPermissionCell = (permission, moduleData, table) => {
  const permissionId = moduleData.availablePermissions.get(permission);
  if (!permissionId) return "NA";
  const onPermissionChange = table.options.meta.select;
  const selectedPermissions = table.options.meta.selectedPermissionIds;
  const isDisable = table.options.meta.disableCheckbox;

  return (
    <Checkbox
      checked={selectedPermissions.includes(permissionId)}
      onChange={(e) => {
        onPermissionChange(permissionId, e.target.checked);
      }}
      sx={checkBoxStyle}
      size="small"
      disabled={isDisable}
    />
  );
};

export const permissionMatrixColumnData = [
  columnHelper.accessor("module", {
    header: "Features",
    cell: ({ row, table }) => <>{capitalizeAndFormat(row?.original?.module)}</>,
  }),
  columnHelper.accessor("read", {
    header: "Read",
    cell: ({ row, table }) => renderPermissionCell("R", row.original, table),
  }),
  columnHelper.accessor("create", {
    header: "Create",
    cell: ({ row, table }) => renderPermissionCell("C", row.original, table),
  }),
  columnHelper.accessor("update", {
    header: "Update",
    cell: ({ row, table }) => renderPermissionCell("U", row.original, table),
  }),
  columnHelper.accessor("delete", {
    header: "Delete",
    cell: ({ row, table }) => renderPermissionCell("D", row.original, table),
  }),
  columnHelper.accessor("status", {
    header: "Status",
    cell: ({ row, table }) => renderPermissionCell("S", row.original, table),
  }),
  columnHelper.accessor("export", {
    header: "Export",
    cell: ({ row, table }) => renderPermissionCell("E", row.original, table),
  }),
  columnHelper.accessor("import", {
    header: "Import",
    cell: ({ row, table }) => renderPermissionCell("I", row.original, table),
  }),
];
