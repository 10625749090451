import {
  ADD_COLUMN_PREFERENCES_TYPE,
  GET__GENERAL_GENCOLUMN_PREFERENCES_TYPE,
  GET_FILTER_DROPDOWN_TYPE,
  UPDATE_COLUMN_PREFERENCES_TYPE,
} from "../../../constant/asynActionsType";
import { createAppAsyncThunk } from "../../../utils/createAppAsyncThunk";
import {
  AddColumnPreferencesService,
  GetFilterDropdownService,
  GetGeneralColumnPreferencesService,
  UpdateColumnPreferencesService,
} from "./commonService";

export const getFilterDropdown = createAppAsyncThunk(
  GET_FILTER_DROPDOWN_TYPE,
  GetFilterDropdownService,
  {}
);

export const getGeneralColumnPreferences = createAppAsyncThunk(
  GET__GENERAL_GENCOLUMN_PREFERENCES_TYPE,
  GetGeneralColumnPreferencesService
);

// Add addColumnPreferences
export const addColumnPreferences = createAppAsyncThunk(
  ADD_COLUMN_PREFERENCES_TYPE,
  AddColumnPreferencesService
);
// Update column preferences
export const upDateColumnPreferences = createAppAsyncThunk(
  UPDATE_COLUMN_PREFERENCES_TYPE,
  UpdateColumnPreferencesService
);
