import {
  AnalyticsIcon,
  HomeIcon,
  InventoryIcon,
  PipelineIcon,
  ReportsIcons,
  SettingsIcon,
  VendorsIcon,
} from "../components/svg";

export const modules = {
  home: "home",
  pipeline: "pipelines",
  inventory: "inventory",
  vendors: "vendors",
  analytics: "analytics",
  reports: "reports",
  users: "users",
  roles: "roles",
  alerts: "alerts",
  profile: "profile",
  change_password: "changepassword",
};
const {
  home,
  pipeline,
  inventory,
  vendors,
  analytics,
  reports,
  users,
  roles,
  alerts,
  profile,
  change_password,
} = modules;

export const permissionsKey = {
  c: "C",
  r: "R",
  u: "U",
  d: "D",
  e: "E",
  i: "I",
  s: "S",
};

export const menuItems = [
  {
    text: "Home",
    icon: HomeIcon,
    path: "/home",
    hasNestedRoutes: true,
    module: [home],
  },
  {
    text: "Pipelines",
    icon: PipelineIcon,
    path: "/pipelines",
    module: [pipeline],
  },
  {
    text: "Inventory",
    icon: InventoryIcon,
    path: "/inventory",
    module: [inventory],
  },
  {
    text: "Vendors Management",
    icon: VendorsIcon,
    path: "/vendors",
    module: [vendors],
  },
  {
    text: "Analytics",
    icon: AnalyticsIcon,
    path: "/analytics",
    module: [analytics],
  },
  {
    text: "Reports",
    icon: ReportsIcons,
    path: "/reports",
    module: [reports],
  },
  {
    text: "Settings",
    icon: SettingsIcon,
    path: "/settings",
    module: [users, roles, alerts, profile, change_password],
  },
];

export const settingsMenuItems = [
  { text: "Users", path: "/settings/users", module: [users] },
  { text: "Roles", path: "/settings/roles", module: [roles] },
  { text: "Alerts", path: "/settings/alerts", module: [alerts] },
];

export const profileSettingsMenuItems = [
  { text: "Profile", path: "/settings/profile", module: [profile] },
  {
    text: "Change Password",
    path: "/settings/change-password",
    module: [change_password],
    state: true,
  },
];

export const headerMenuItems = [
  ...profileSettingsMenuItems,

  { text: "Logout" },
];

export const accessOptions = [
  { value: "super_admin", label: "Super Admin" },
  { value: "sc", label: "SC Admin" },
  { value: "read", label: "Read" },
  { value: "write", label: "Write" },
  { value: "admin", label: "Admin" },
];

export const statusOptions = [
  {
    value: "Pending",
    label: "Pending",
  },
  {
    value: "Active",
    label: "Active",
  },
  {
    value: "Inactive",
    label: "Inactive",
  },
];

export const exportMenuData = [
  { text: "EXCEL" },
  // { text: "PDF" },
  // { text: "DOC" },
];

export const matchSortKey = {
  created_by: "created_by__name",
  updated_by: "updated_by__name",
  roles: "roles__role_name",
};

export const allowedRoutes = ["/", "/access", "/logout"];

export const openMainLayoutRoutes = ["/access", "/logout"];
