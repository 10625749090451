import axiosConfig from "../../../config/axiosConfig";
import {
  ALTERNATE_ROLE_URL,
  CHANGE_PASSWORD_URL,
  GET_FEATURES_URL,
  ROLES_URL,
  USER_PROFILE_URL,
  USERS_URL,
} from "../../../config/config";
import { createQueryParams, getStringFromArray } from "../../../utils/helper";

export const GetUserService = (params) => {
  try {
    const {
      page,
      per_page,
      name,
      email,
      designation,
      department,
      role = [],
      status = [],
      ordering,
    } = params || {};

    const paramsList = {
      page,
      per_page,
      name,
      email,
      designation,
      department,
      roles: getStringFromArray(role),
      status: getStringFromArray(status),
      ordering,
    };

    const queryString = createQueryParams(paramsList);

    const url = `${USERS_URL}${queryString ? `?${queryString}` : ""}`;

    return axiosConfig.get(url);
  } catch (error) {
    throw error;
  }
};

export const AddUserService = (data) => {
  return axiosConfig.post(USERS_URL, data);
};

export const UpdateUserService = (data) => {
  return axiosConfig.patch(USERS_URL, data);
};

export const DeleteUserService = (data) => {
  return axiosConfig.delete(`${USERS_URL}/${data?.id}`, data);
};

export const AddRoleService = (data) => {
  return axiosConfig.post(ROLES_URL, data);
};

export const UpdateRoleService = (data) => {
  return axiosConfig.patch(ROLES_URL, data);
};

export const DeleteRoleService = (data) => {
  return axiosConfig.delete(`${ROLES_URL}/${data?.id}`, data);
};

export const AlternateRoleService = (data) => {
  return axiosConfig.post(ALTERNATE_ROLE_URL, data);
};

export const GetRolesService = (params) => {
  const { page, per_page, role_name, profile_type, created_by, ordering } =
    params;

  const paramsList = {
    page,
    per_page,
    role_name,
    profile_type,
    created_by: getStringFromArray(created_by),
    ordering,
  };
  const queryString = createQueryParams(paramsList);
  const url = `${ROLES_URL}${queryString ? `?${queryString}` : ""}`;
  return axiosConfig.get(url);
};

export const ChangePasswordService = (data) => {
  return axiosConfig.post(CHANGE_PASSWORD_URL, data);
};

export const GetFeaturesService = (params) => {
  const { page, per_page } = params;
  return axiosConfig.get(
    `${GET_FEATURES_URL}?page=${page}&per_page=${per_page}`
  );
};

export const updateUserProfileService = ({ data, headers }) => {
  return axiosConfig.patch(USER_PROFILE_URL, data, { headers });
};
