import { createSlice } from "@reduxjs/toolkit";
import {
  getVendorCompanyClassGraph,
  getVendorOfficeRegionGraph,
  getVendorDateCreatedGraph,
  getVendorCustomerQuotesDetails,
  getVendorInvoicesDetails,
  getVendorPODetails,
  getVendorQuotesDetails,
  getVendorRepairDetails,
  getVendors,
  getVendorSODetails,
} from "./vendorsManagementActions";

export const initialState = {
  isInitialLoad: true,
  poDetailInitialLoad: true,
  soDetailInitialLoad: true,
  customerQuotesDetailInitialLoad: true,
  quotesDetailInitialLoad: true,
  invoicesDetailInitialLoad: true,
  repairDetailInitialLoad: true,
  loading: false,
  vendors: {},
  vendorPODetails: {},
  vendorSODetails: {},
  vendorCustomerQuotesDetails: {},
  vendorQuotesDetails: {},
  vendorInvoicesDetails: {},
  vendorsRepairDetails: {},
  columnPreferences: {},
  companyClass: {},
  officeRegion: {},
  dateCreated: {},
  addLoading: false,
};

const vendorsSlice = createSlice({
  name: "vendors",
  initialState,
  reducers: {
    setInitialLoad: (state, action) => {
      state.isInitialLoad = action.payload;
    },
    setPODetailInitialLoad: (state, action) => {
      state.poDetailInitialLoad = action.payload;
    },
    setSODetailInitialLoad: (state, action) => {
      state.soDetailInitialLoad = action.payload;
    },
    setCustomerQuotesDetailInitialLoad: (state, action) => {
      state.customerQuotesDetailInitialLoad = action.payload;
    },
    setQuotesDetailInitialLoad: (state, action) => {
      state.quotesDetailInitialLoad = action.payload;
    },
    setInvoicsDetailInitialLoad: (state, action) => {
      state.invoicesDetailInitialLoad = action.payload;
    },
    setRepairDetailInitialLoad: (state, action) => {
      state.repairDetailInitialLoad = action.payload;
    },
    clearVendorsData: (state) => {
      state.isInitialLoad = true;
      state.repairDetailInitialLoad = true;
      state.poDetailInitialLoad = true;
      state.soDetailInitialLoad = true;
      state.quotesDetailInitialLoad = true;
      state.invoicesDetailInitialLoad = true;
      state.customerQuotesDetailInitialLoad = true;
      state.loading = false;
      state.vendors = {};
      state.vendorPODetails = {};
      state.vendorSODetails = {};
      state.vendorCustomerQuotesDetails = {};
      state.vendorQuotesDetails = {};
      state.vendorInvoicesDetails = {};
      state.vendorsRepairDetails = {};
    },
  },
  extraReducers: (builder) => {
    builder
      // vendors list
      .addCase(getVendors.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVendors.fulfilled, (state, action) => {
        state.loading = false;
        state.isInitialLoad = false;
        state.vendors = action.payload;
      })
      .addCase(getVendors.rejected, (state) => {
        state.loading = false;
        state.isInitialLoad = false;
      })
      // vendors po details
      .addCase(getVendorPODetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVendorPODetails.fulfilled, (state, action) => {
        state.loading = false;
        state.vendorPODetails = action.payload;
        state.poDetailInitialLoad = false;
      })
      .addCase(getVendorPODetails.rejected, (state) => {
        state.loading = false;
        state.poDetailInitialLoad = false;
      })
      // vendors so details
      .addCase(getVendorSODetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVendorSODetails.fulfilled, (state, action) => {
        state.loading = false;
        state.vendorSODetails = action.payload;
        state.soDetailInitialLoad = false;
      })
      .addCase(getVendorSODetails.rejected, (state) => {
        state.loading = false;
        state.soDetailInitialLoad = false;
      })
      // vendors customer quotes details
      .addCase(getVendorCustomerQuotesDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVendorCustomerQuotesDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.vendorCustomerQuotesDetails = action.payload;
        state.customerQuotesDetailInitialLoad = false;
      })
      .addCase(getVendorCustomerQuotesDetails.rejected, (state) => {
        state.loading = false;
        state.customerQuotesDetailInitialLoad = false;
      })
      // vendors invoices details
      .addCase(getVendorInvoicesDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVendorInvoicesDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.vendorInvoicesDetails = action.payload;
        state.invoicesDetailInitialLoad = false;
      })
      .addCase(getVendorInvoicesDetails.rejected, (state) => {
        state.loading = false;
        state.invoicesDetailInitialLoad = false;
      })
      // vendors quotes details
      .addCase(getVendorQuotesDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVendorQuotesDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.vendorQuotesDetails = action.payload;
        state.quotesDetailInitialLoad = false;
      })
      .addCase(getVendorQuotesDetails.rejected, (state) => {
        state.loading = false;
        state.quotesDetailInitialLoad = false;
      })
      // vendors repair details
      .addCase(getVendorRepairDetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVendorRepairDetails.fulfilled, (state, action) => {
        state.loading = false;
        state.vendorsRepairDetails = action.payload;
        state.repairDetailInitialLoad = false;
      })
      .addCase(getVendorRepairDetails.rejected, (state) => {
        state.loading = false;
        state.repairDetailInitialLoad = false;
      })
      //Graphs
      .addCase(getVendorCompanyClassGraph.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVendorCompanyClassGraph.fulfilled, (state, action) => {
        state.loading = false;
        state.companyClass = action.payload.data;
      })
      .addCase(getVendorCompanyClassGraph.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getVendorOfficeRegionGraph.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVendorOfficeRegionGraph.fulfilled, (state, action) => {
        state.loading = false;
        state.officeRegion = action.payload.data;
      })
      .addCase(getVendorOfficeRegionGraph.rejected, (state) => {
        state.loading = false;
      })

      .addCase(getVendorDateCreatedGraph.pending, (state) => {
        state.loading = true;
      })
      .addCase(getVendorDateCreatedGraph.fulfilled, (state, action) => {
        state.loading = false;
        state.dateCreated = action.payload.data;
      })
      .addCase(getVendorDateCreatedGraph.rejected, (state) => {
        state.loading = false;
      });
  },
});

export const {
  setInitialLoad,
  clearVendorsData,
  setPODetailInitialLoad,
  setSODetailInitialLoad,
  setCustomerQuotesDetailInitialLoad,
  setQuotesDetailInitialLoad,
  setInvoicsDetailInitialLoad,
  setRepairDetailInitialLoad,
} = vendorsSlice.actions;
export default vendorsSlice.reducer;
