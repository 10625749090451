import {
  Skeleton,
  TableCell,
  TableRow,
  Table,
  TableBody,
  TableHead,
  TableContainer,
  Paper,
} from "@mui/material";
const TableSkeleton = ({ columns }) => {
  return (
    <TableContainer
      component={Paper}
      sx={{
        border: "1px solid var(--alto-color)",
        borderRadius: "0",
        overflow: "auto",
        boxShadow: "none",
      }}
    >
      <Table>
        <TableHead>
          <TableRow
            sx={{
              backgroundColor: "var(--blackhaze-color)",
            }}
          >
            {columns.map((column, index) => (
              <TableCell
                key={index}
                sx={{
                  height: "37px",
                  p: "8px",
                  color: "var(--primary-color)",
                  fontSize: "var(--font-size-13)",
                  fontWeight: "var(--font-semibold)",
                  borderBottom: "1px solid var(--alto-color)",
                  borderRight: "none",
                }}
              >
                <Skeleton variant="text" />
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {[...Array(10)].map((_, rowIndex) => (
            <TableRow key={rowIndex}>
              {columns.map((_, colIndex) => (
                <TableCell
                  key={colIndex}
                  sx={{
                    height: "37px",
                    backgroundColor: "var(--secondary-color)",
                    "&:last-child td, &:last-child th": {
                      borderBottom: 0,
                    },
                    "& td": {
                      color: "var(--primary-color)",
                      fontSize: "var(--font-size-13)",
                      fontWeight: "var(--font-regular)",
                      p: "8px",
                    },
                  }}
                >
                  <Skeleton variant="text" />
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableSkeleton;
