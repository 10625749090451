import { createSlice } from "@reduxjs/toolkit";
import {
  getInRepairInventoriesList,
  getInventories,
  getInventoriesFilter,
  getInventoryCountryNameTotalQtyGraph,
  getInventoryPartClassTotalQtyGraph,
  getInventoryPartTypeTotalQtyGraph,
  getInventoryTierGraphCountGraph,
  getStockSummaryInventoryDetails,
  getUnderStockInventoriesList,
} from "./inventoriesAction";

export const initialState = {
  isInitialLoad: true,
  isInitialLoadForInRepair: true,
  loading: false,
  addLoading: false,
  isGraphdataLoading: false,
  inventories: {},
  inRepairInventories: {},
  inventoriesFilter: {},
  columnPreferences: {},
  underStockInventories: {},
  underStockDetails: {},
  isStockDetailsLoading: false,
  country: {},
  partCalss: {},
  tire: {},
  partType: {},
};

const inventoriesSlice = createSlice({
  name: "inventories",
  initialState,
  reducers: {
    setInitialLoad: (state, action) => {
      state.isInitialLoad = action.payload;
    },
    clearInventoriesData: (state) => {
      state.isInitialLoad = true;
      state.loading = false;
      state.addLoading = false;
      state.inventories = {};
      state.inRepairInventories = {};
      state.inventoriesFilter = {};
      state.columnPreferences = {};
    },
  },
  extraReducers: (builder) => {
    // Inventories list
    builder
      .addCase(getInventories.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInventories.fulfilled, (state, action) => {
        state.loading = false;
        state.isInitialLoad = false;
        state.inventories = action.payload;
      })
      .addCase(getInventories.rejected, (state) => {
        state.loading = false;
        state.isInitialLoad = false;
      })
      // Inventories Filter
      .addCase(getInventoriesFilter.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInventoriesFilter.fulfilled, (state, action) => {
        state.loading = false;

        state.inventoriesFilter = action.payload;
      })
      .addCase(getInventoriesFilter.rejected, (state) => {
        state.loading = false;
      })
      // In-Repair Inventories
      .addCase(getInRepairInventoriesList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getInRepairInventoriesList.fulfilled, (state, action) => {
        state.loading = false;
        state.inRepairInventories = action.payload;
        state.isInitialLoadForInRepair = false;
      })
      .addCase(getInRepairInventoriesList.rejected, (state, action) => {
        state.loading = false;
        state.inRepairInventories = action.payload;
        state.isInitialLoadForInRepair = false;
      })

      // Under Stock Inventories
      .addCase(getUnderStockInventoriesList.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUnderStockInventoriesList.fulfilled, (state, action) => {
        state.loading = false;
        state.underStockInventories = action.payload;
        state.isInitialLoadForInRepair = false;
      })
      .addCase(getUnderStockInventoriesList.rejected, (state, action) => {
        state.loading = false;
        state.underStockInventories = action.payload;
        state.isInitialLoadForInRepair = false;
      })

      // Under stock details
      .addCase(getStockSummaryInventoryDetails.pending, (state) => {
        state.isStockDetailsLoading = true;
      })
      .addCase(getStockSummaryInventoryDetails.fulfilled, (state, action) => {
        state.isStockDetailsLoading = false;
        state.underStockDetails = action.payload;
        state.isInitialLoadForInRepair = false;
      })
      .addCase(getStockSummaryInventoryDetails.rejected, (state, action) => {
        state.isStockDetailsLoading = false;
        state.underStockDetails = action.payload;
        state.isInitialLoadForInRepair = false;
      })

      //Graph

      .addCase(getInventoryCountryNameTotalQtyGraph.pending, (state) => {
        state.isGraphdataLoading = true;
      })
      .addCase(
        getInventoryCountryNameTotalQtyGraph.fulfilled,
        (state, action) => {
          state.isGraphdataLoading = false;
          state.country = action.payload.data;
          state.isInitialLoadForInRepair = false;
        }
      )
      .addCase(
        getInventoryCountryNameTotalQtyGraph.rejected,
        (state, action) => {
          state.isGraphdataLoading = false;
          state.country = action.payload.data;
          state.isInitialLoadForInRepair = false;
        }
      )

      .addCase(getInventoryPartClassTotalQtyGraph.pending, (state) => {
        state.isGraphdataLoading = true;
      })
      .addCase(
        getInventoryPartClassTotalQtyGraph.fulfilled,
        (state, action) => {
          state.isGraphdataLoading = false;
          state.partCalss = action.payload.data;
          state.isInitialLoadForInRepair = false;
        }
      )
      .addCase(getInventoryPartClassTotalQtyGraph.rejected, (state, action) => {
        state.isGraphdataLoading = false;
        state.partCalss = action.payload.data;
        state.isInitialLoadForInRepair = false;
      })

      .addCase(getInventoryTierGraphCountGraph.pending, (state) => {
        state.isGraphdataLoading = true;
      })
      .addCase(getInventoryTierGraphCountGraph.fulfilled, (state, action) => {
        state.isGraphdataLoading = false;
        state.tire = action.payload.data;
        state.isInitialLoadForInRepair = false;
      })
      .addCase(getInventoryTierGraphCountGraph.rejected, (state, action) => {
        state.isGraphdataLoading = false;
        state.tire = action.payload.data;
        state.isInitialLoadForInRepair = false;
      })

      .addCase(getInventoryPartTypeTotalQtyGraph.pending, (state) => {
        state.isGraphdataLoading = true;
      })
      .addCase(getInventoryPartTypeTotalQtyGraph.fulfilled, (state, action) => {
        state.isGraphdataLoading = false;
        state.partType = action.payload.data;
        state.isInitialLoadForInRepair = false;
      })
      .addCase(getInventoryPartTypeTotalQtyGraph.rejected, (state, action) => {
        state.isGraphdataLoading = false;
        state.partType = action.payload.data;
        state.isInitialLoadForInRepair = false;
      });
  },
});

export const {
  setInitialLoad,
  loading,
  clearInventoriesData,
  isInitialLoadForInRepair,
} = inventoriesSlice.actions;
export default inventoriesSlice.reducer;
