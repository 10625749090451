import axiosConfig from "../../../config/axiosConfig";
import {
  ADD_COLUMN_PREFERENCES_URL,
  GET_FILTER_DROPDOWN_URL,
  GET_GENERAL_COLUMN_PREFERENCES_URL,
  UPDATE_COLUMN_PREFERENCES_URL,
} from "../../../config/config";

export const GetFilterDropdownService = async (params) => {
  const { module_name } = params;
  return axiosConfig.get(
    `${GET_FILTER_DROPDOWN_URL}?module_name=${module_name}`
  );
};

export const GetGeneralColumnPreferencesService = (params) => {
  return axiosConfig.get(`${GET_GENERAL_COLUMN_PREFERENCES_URL}`);
};

export const AddColumnPreferencesService = (data) => {
  return axiosConfig.post(ADD_COLUMN_PREFERENCES_URL, data);
};

export const GetColumnPreferencesService = (data) => {
  return axiosConfig.get(GET_GENERAL_COLUMN_PREFERENCES_URL, data);
};

export const UpdateColumnPreferencesService = (params) => {
  const { id, filed, module_name } = params;
  return axiosConfig.put(`${UPDATE_COLUMN_PREFERENCES_URL}/${id}/`, {
    module_name: module_name,
    column_preferences: filed,
  });
};
