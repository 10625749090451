import {
  GET_IN_REPAIR_INVENTORIES_TYPE,
  GET_INVENTORIES_COUNTRY_NAME_TOTAL_QTY_GRAPH_TYPE,
  GET_INVENTORIES_FILTER_TYPE,
  GET_INVENTORIES_LIST_TYPE,
  GET_INVENTORIES_PART_TYPE_TOTAL_QTY_GARPH_TYPE,
  GET_INVENTORY_PART_CLASS_TOTAL_QTY_GRAPH_TYPE,
  GET_INVENTORY_TIER_GRAPH_COUNT_GRAPH_TYPE,
  GET_STOCK_SUMMARY_INVENTORY_DETAILS_TYPE,
  GET_UNDER_STOCK_INVENTORIES_TYPE,
} from "../../../constant/asynActionsType";
import { createAppAsyncThunk } from "../../../utils/createAppAsyncThunk";
import {
  GetInRepairInventoriesService,
  GetInventoriesFilterService,
  GetInventoriesService,
  GetInventoryCountryNameTotalQtyGraphService,
  GetInventoryPartClassTotalQtyGraphService,
  GetInventoryPartTypeTotalQtyGraphService,
  GetInventoryStockSummaryDetailsService,
  GetInventoryTierGraphCountGraphService,
  GetUnderStockInventoriesService,
} from "./InventoriesServices";

// listing

export const getInventories = createAppAsyncThunk(
  GET_INVENTORIES_LIST_TYPE,
  GetInventoriesService,
  {
    errorMessage: {
      show: true,
    },
  }
);

export const getInventoriesFilter = createAppAsyncThunk(
  GET_INVENTORIES_FILTER_TYPE,
  GetInventoriesFilterService
);

export const getInRepairInventoriesList = createAppAsyncThunk(
  GET_IN_REPAIR_INVENTORIES_TYPE,
  GetInRepairInventoriesService
);

export const getUnderStockInventoriesList = createAppAsyncThunk(
  GET_UNDER_STOCK_INVENTORIES_TYPE,
  GetUnderStockInventoriesService
);

export const getStockSummaryInventoryDetails = createAppAsyncThunk(
  GET_STOCK_SUMMARY_INVENTORY_DETAILS_TYPE,
  GetInventoryStockSummaryDetailsService
);

// Graph

export const getInventoryCountryNameTotalQtyGraph = createAppAsyncThunk(
  GET_INVENTORIES_COUNTRY_NAME_TOTAL_QTY_GRAPH_TYPE,
  GetInventoryCountryNameTotalQtyGraphService
);

export const getInventoryPartClassTotalQtyGraph = createAppAsyncThunk(
  GET_INVENTORY_PART_CLASS_TOTAL_QTY_GRAPH_TYPE,
  GetInventoryPartClassTotalQtyGraphService
);

export const getInventoryTierGraphCountGraph = createAppAsyncThunk(
  GET_INVENTORY_TIER_GRAPH_COUNT_GRAPH_TYPE,
  GetInventoryTierGraphCountGraphService
);

export const getInventoryPartTypeTotalQtyGraph = createAppAsyncThunk(
  GET_INVENTORIES_PART_TYPE_TOTAL_QTY_GARPH_TYPE,
  GetInventoryPartTypeTotalQtyGraphService
);
