import { createSlice } from "@reduxjs/toolkit";
import {
  addColumnPreferences,
  getFilterDropdown,
  getGeneralColumnPreferences,
  upDateColumnPreferences,
} from "./commonActions";

export const initialState = {
  loading: false,
  filterDropdownValues: {},
  columnPreferences: {},
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    clearCommonState: (state) => {
      state.loading = false;
      state.filterDropdownValues = {};
    },
  },
  extraReducers: (builder) => {
    builder

      // get filter dropdown
      .addCase(getFilterDropdown.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFilterDropdown.fulfilled, (state, action) => {
        state.loading = false;
        state.filterDropdownValues = action.payload?.data;
      })
      .addCase(getFilterDropdown.rejected, (state, action) => {
        state.loading = false;
      })

      // General Column Preferences
      .addCase(getGeneralColumnPreferences.pending, (state) => {
        state.loading = true;
      })
      .addCase(getGeneralColumnPreferences.fulfilled, (state, action) => {
        state.loading = false;

        state.columnPreferences = action.payload.result;
      })
      .addCase(getGeneralColumnPreferences.rejected, (state) => {
        state.loading = false;
      })
      // ADD Column Preferences
      .addCase(addColumnPreferences.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(addColumnPreferences.fulfilled, (state, action) => {
        state.addLoading = false;

        state.columnPreferences = action.payload.result;
      })
      .addCase(addColumnPreferences.rejected, (state) => {
        state.addLoading = false;
      })

      // Update Column Preferences
      .addCase(upDateColumnPreferences.pending, (state) => {
        state.loading = true;
      })
      .addCase(upDateColumnPreferences.fulfilled, (state, action) => {
        state.loading = false;
        state.columnPreferences = action.payload.result.column_preferences;
      })
      .addCase(upDateColumnPreferences.rejected, (state) => {
        state.loading = false;
      });
  },
});
export const { clearCommonState } = commonSlice.actions;
export default commonSlice.reducer;
