import { enqueueSnackbar } from "notistack";
import { persistor, store } from "../redux/store";
import { setDefaultPath } from "../redux/features/auth/authSlice";

const TOKEN_STORAGE = {
  ACCESS_TOKEN: "token",
  REFRESH_TOKEN: "refresh_token",
  SSO: "sso_login",
};

const storageType = "cookie";
// Setter for access token
export const setAccessToken = (token) => {
  if (storageType === "cookie") {
    document.cookie = `${TOKEN_STORAGE.ACCESS_TOKEN}=${token}; path=/; max-age=172800; SameSite=Strict`; // 2 days in seconds
  } else {
    sessionStorage.setItem(TOKEN_STORAGE.ACCESS_TOKEN, token);
  }
};

// Setter for refresh token
export const setRefreshToken = (token) => {
  if (storageType === "cookie") {
    document.cookie = `${TOKEN_STORAGE.REFRESH_TOKEN}=${token}; path=/; max-age=172800; SameSite=Strict`; // 2 days in seconds
  } else {
    sessionStorage.setItem(TOKEN_STORAGE.REFRESH_TOKEN, token);
  }
};

export const setSSOFlags = (flag) => {
  const flagValue = String(flag);
  if (storageType === "cookie") {
    document.cookie = `${TOKEN_STORAGE.SSO}=${flagValue}; path=/; max-age=172800; SameSite=Strict`; // 2 days in seconds
  } else {
    sessionStorage.setItem(TOKEN_STORAGE.SSO, flagValue);
  }
};

// Getter for access token
export const getAccessToken = () => {
  return storageType === "cookie"
    ? getCookie(TOKEN_STORAGE.ACCESS_TOKEN)
    : sessionStorage.getItem(TOKEN_STORAGE.ACCESS_TOKEN);
};

// Getter for refresh token
export const getRefreshToken = () => {
  return storageType === "cookie"
    ? getCookie(TOKEN_STORAGE.REFRESH_TOKEN)
    : sessionStorage.getItem(TOKEN_STORAGE.REFRESH_TOKEN);
};

export const getSSOFlag = () => {
  const flagValue =
    storageType === "cookie"
      ? getCookie(TOKEN_STORAGE.SSO)
      : sessionStorage.getItem(TOKEN_STORAGE.SSO);

  if (flagValue === null || flagValue === undefined) return false;
  return flagValue.toLowerCase() === "true";
};

// Helper method to get cookie by name
export const getCookie = (name) => {
  const cookieValue = document.cookie
    .split("; ")
    .find((row) => row.startsWith(`${name}=`));
  return cookieValue ? cookieValue.split("=")[1] : null;
};

// Clear all tokens
export const clearTokens = () => {
  if (storageType === "cookie") {
    document.cookie = `${TOKEN_STORAGE.ACCESS_TOKEN}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    document.cookie = `${TOKEN_STORAGE.REFRESH_TOKEN}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    document.cookie = `${TOKEN_STORAGE.SSO}=; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  } else {
    sessionStorage.removeItem(TOKEN_STORAGE.ACCESS_TOKEN);
    sessionStorage.removeItem(TOKEN_STORAGE.REFRESH_TOKEN);
    sessionStorage.removeItem(TOKEN_STORAGE.SSO);
  }
};

export const logout = () => {
  store.dispatch({ type: "auth/logout" });
  store.dispatch(setDefaultPath(""));
  localStorage.clear();
  persistor.persist();
  clearTokens();
  enqueueSnackbar("Your Session has expired ! please login again", {
    variant: "error",
  });
  window.location.href = "/";
};
