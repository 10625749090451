export const EMAIL = "email";
export const PASSWORD = "password";
export const OLD_PASSWORD = "old_password";
export const NEW_PASSWORD = "new_password";
export const CONFIRM_PASSWORD = "confirm_password";

export const pending = "Pending";
export const active = "Active";
export const inactive = "Inactive";

export const NAME = "name";
export const ROLE_NAME = "role_name";
export const MOBILE = "mobile_number";
export const DESIGNATION = "designation";
export const DEPARTMENT = "department";
export const ACCESS = "roles";
export const PROFILE_TYPE = "profile_type";
export const UPDATED_ROLE = "updated_role";
export const STATUS = "status";
// features
export const HOME = "Home";
export const PIPELINE = "Pipelines";
export const INVENTORY = "Inventory";
export const VENDORS = "Vendors Management";
export const ANALYTICS = "Analytics";
export const REPORTS = "Reports";
export const USERS = "Users";
export const ROLES = "Roles";
export const ALERTS = "Alerts";

// stautus

export const SUCCESS = "SUCCESS";
export const FAIL = "FAIL";

export const accessor = "accessorKey";

export const list = "list";
export const chart = "chart";
export const tooltTitle = {
  edit: "Edit",
  delete: "Delete",
  view: "View",
  resend_activ_link: "Resend Activation Link",
  history: "History",
};
