export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
// auth
export const LOGIN_URL = "users/auth/login";
export const VALIDATE_LINK_URL = "users/validate_link";
export const VALIDATE_AZURE_LINK_URL = "users/entra/ssoSuccess";
export const SET_PASSWORD_URL = "users/auth/password/reset";
export const FORGOT_PASSWORD_URL = "users/auth/password/forgot";
export const SEND_PASSWORD_LINK_URL = "users/auth/resend";
export const REFRESH_TOKEN_URL = `${BASE_URL}users/auth/refresh`;
export const LOGOUT_URL = "users/auth/logout";
export const AZURE_LOGIN_URL = "entra/auth/login";
export const AZUERE_LOGOUT_URL = "entra/auth/logout";
// setings
export const USERS_URL = "users/users";
export const ROLES_URL = "users/roles";
export const ALTERNATE_ROLE_URL = "users/updateuserrole";
export const CHANGE_PASSWORD_URL = "users/auth/password/change";
export const GET_FEATURES_URL = "users/features";
export const USER_PROFILE_URL = "users/profile";

// inventories
export const GET_INVENTORY_URL = "inventory/items";
export const GET_INVENTORY_FILTER_URL = "inventory/items/inventory_dropdown";
export const GET_GENERAL_COLUMN_PREFERENCES_URL =
  "config/general-column-preferences/";
export const GET_INVENTORY_STOCK_SUMMARY_DETAILS_URL =
  "/inventory/items/inventory_stock_summary";
export const ADD_COLUMN_PREFERENCES_URL = "config/column-preferences/";
export const UPDATE_COLUMN_PREFERENCES_URL = `config/column-preferences`;
export const GET_IN_REPAIR_INVENTORIES_URL =
  "inventory/items/inventory_in_repair";
export const INVENTORY_EXPORT_EXCEL_URL = "inventory/items/download_inventory";
export const UNDER_STOCK_INVENTORIES_URL =
  "inventory/items/inventory_under_stock";
export const GET_INVENTORIES_COUNTRY_NAME_TOTAL_QTY_GRAPH_URL =
  "inventory/graphs/country_name_qty_graph_analytics";
export const GET_INVENTORY_PART_CLASS_TOTAL_QTY_GRAPH_URL =
  "inventory/graphs/part_class_graph_analytics";
export const GET_INVENTORY_TIER_GRAPH_COUNT_GRAPH_URL =
  "inventory/graphs/tier_graph_analytics";
export const GET_INVENTORIES_PART_TYPE_TOTAL_QTY_GARPH_URL =
  "inventory/graphs/tier_graph_analytics";
// vendors
export const VENDORSLIST_URL = "vendors/list";
export const VENDORS_URL = "vendors";
export const VENDOR_COMPANY_CLASS_GRAPH_URL = "vendors/graphs/company-class";
export const VENDOR_OFFICE_REGION_GRAPH_URL = "vendors/graphs/office-region";
export const VENDOR_DATE_CREATED_GRAPH_URL = "vendors/graphs/date-created";

// export excel url
export const EXPORT__AUTH_EXCEL_URL = "users/export";
export const EXPORT__EXCEL_URL = "export-excel";

// common
export const GET_FILTER_DROPDOWN_URL = "config/dropdown-values";
