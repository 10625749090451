import {
  ADD_ROLE_TYPE,
  ADD_USER_TYPE,
  ALTERNATE_ROLE_TYPE,
  CHANGE_PASSWORD_TYPE,
  DELETE_ROLE_TYPE,
  DELETE_USER_TYPE,
  GET_FEATURES_TYPE,
  GET_ROLES_TYPE,
  GET_USERS_TYPE,
  UPDATE_PROFILE_TYPE,
  UPDATE_ROLE_TYPE,
  UPDATE_USER_TYPE,
} from "../../../constant/asynActionsType";
import { createAppAsyncThunk } from "../../../utils/createAppAsyncThunk";
import {
  AddRoleService,
  AddUserService,
  AlternateRoleService,
  ChangePasswordService,
  DeleteRoleService,
  DeleteUserService,
  GetFeaturesService,
  GetRolesService,
  GetUserService,
  UpdateRoleService,
  updateUserProfileService,
  UpdateUserService,
} from "./settingsServices";

export const getUsers = createAppAsyncThunk(GET_USERS_TYPE, GetUserService, {});

export const addUser = createAppAsyncThunk(ADD_USER_TYPE, AddUserService, {
  successMessage: { show: true },
  errorMessage: { show: true },
});

export const updateUser = createAppAsyncThunk(
  UPDATE_USER_TYPE,
  UpdateUserService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const deleteUser = createAppAsyncThunk(
  DELETE_USER_TYPE,
  DeleteUserService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const getRoles = createAppAsyncThunk(
  GET_ROLES_TYPE,
  GetRolesService,
  {}
);

export const addRole = createAppAsyncThunk(ADD_ROLE_TYPE, AddRoleService, {
  successMessage: { show: true },
  errorMessage: { show: true },
});

export const updateRole = createAppAsyncThunk(
  UPDATE_ROLE_TYPE,
  UpdateRoleService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const deleteRole = createAppAsyncThunk(
  DELETE_ROLE_TYPE,
  DeleteRoleService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const assignAlternateRole = createAppAsyncThunk(
  ALTERNATE_ROLE_TYPE,
  AlternateRoleService,
  {
    successMessage: { show: true },
    errorMessage: { show: true },
  }
);

export const changePassword = createAppAsyncThunk(
  CHANGE_PASSWORD_TYPE,
  ChangePasswordService,
  {
    successMessage: {
      show: true,
    },
    errorMessage: {
      show: true,
    },
  }
);

export const getFeatures = createAppAsyncThunk(
  GET_FEATURES_TYPE,
  GetFeaturesService,
  {}
);

export const updateUserProfile = createAppAsyncThunk(
  UPDATE_PROFILE_TYPE,
  updateUserProfileService,
  {
    successMessage: {
      show: true,
    },
    errorMessage: {
      show: true,
    },
  }
);
