import { lazy } from "react";

// rootroute
export const LazyRootRoute = lazy(
  () => import("../components/route/RootRoute")
);
export const LazyMainLayout = lazy(
  () => import("../components/layout/main-layout/Layout")
);

// layouts
export const LazyAuthLayout = lazy(
  () => import("../components/layout/auth-layout/AuthLayout")
);

// auth pages
export const LazyLogin = lazy(() => import("../pages/auth/login/Login"));
export const LazyForgotPassword = lazy(
  () => import("../pages/auth/forgot-password/ForgotPassword")
);
export const LazyResetPassword = lazy(
  () => import("../pages/auth/reset-password/ResetPassword")
);
export const LazyAzureLogin = lazy(
  () => import("../pages/auth/login/AzureLogin")
);
export const LazyAzureLogout = lazy(
  () => import("../pages/auth/logout/AuzureLogout")
);

// private pages
export const LazyHome = lazy(() => import("../pages/home/Home"));
export const LazyPipeline = lazy(() => import("../pages/pipeline/Pipeline"));
export const LazyInventory = lazy(
  () => import("../pages/inventory/InventoryLayout")
);
export const LazyInventoryDetails = lazy(
  () => import("../pages/inventory/inventories/InventoriesDetailsLayout")
);
export const LazyVendor = lazy(
  () => import("../pages/vendor-management/VendorManagement")
);
export const LazyVendorDetail = lazy(
  () => import("../pages/vendor-management/VendorManagementDetail")
);
export const LazyAnalytics = lazy(() => import("../pages/analytics/Analytics"));
export const LazyReports = lazy(() => import("../pages/reports/Reports"));
export const LazySettingsLayout = lazy(
  () => import("../pages/settings/settings-layout/SettingsLayout")
);
export const LazyUsersSettings = lazy(
  () => import("../pages/settings/users/UsersSettings")
);
export const LazyRolesSettings = lazy(
  () => import("../pages/settings/roles/RolesSettings")
);
export const LazyAlertsSettings = lazy(
  () => import("../pages/settings/alerts/Alerts")
);

export const LazyProfileSettings = lazy(
  () => import("../pages/settings/profile/ProfileSettings")
);

export const LazyChangePassword = lazy(
  () => import("../pages/settings/change-password/ChangePassword")
);
export const Lazy404Page = lazy(
  () => import("../pages/404error-page/NotFoundPage")
);

export const LazyComingSoon = lazy(
  () => import("../components/coming-soon/ComingSoon")
);
