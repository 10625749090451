export const chipStyle = {
  height: "17px",
  width: "64px",
  color: "var(--secondary-color)",
  fontSize: "var(--font-size-13)",
  fontWeight: "var(--font-regular)",
  backgroundColor: "var(--seagreen-color)",
  border: "1px solid var(--emerald-color)",
  borderRadius: "8px",
  "& .MuiChip-label ": {
    p: 0,
  },
};

export const getStatusStyle = (style) => {
  return { ...chipStyle, ...style };
};
