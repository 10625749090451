import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import RefreshIcon from "@mui/icons-material/Refresh";
import HomeIcon from "@mui/icons-material/Home";
import { useSelector } from "react-redux";

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const navigate = useNavigate();
  const { defaultPath } = useSelector((state) => state.auth);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: 3,
        textAlign: "center",
      }}
    >
      <Typography variant="h4" gutterBottom>
        Something went wrong
      </Typography>
      <Typography color="text.secondary" sx={{ mb: 4 }}>
        {error.message}
      </Typography>
      <Box sx={{ display: "flex", gap: 2 }}>
        <Button
          variant="contained"
          startIcon={<RefreshIcon />}
          onClick={resetErrorBoundary}
          sx={{
            backgroundColor: "var(--bondiblue-color)",
            color: "var(--secondary-color)",
            borderRadius: "4px",
            fontSize: "var(--font-size-15)",
            fontWeight: "var(--font-semibold)",
          }}
        >
          Try Again
        </Button>
        <Button
          variant="outlined"
          startIcon={<HomeIcon />}
          onClick={() => navigate(defaultPath ? defaultPath : "/")}
          sx={{
            backgroundColor: "var(--secondary-color)",
            color: "var(--bondiblue-color)",
            border: "1px solid var(--bondiblue-color)",
            borderRadius: "4px",
            fontSize: "var(--font-size-15)",
            fontWeight: "var(--font-semibold)",
          }}
        >
          Go to Home
        </Button>
      </Box>
    </Box>
  );
};

export default ErrorFallback;
