import { Suspense } from "react";
import { LazyRootRoute } from "./constant/lazyImports";
import { getAccessToken } from "./utils/tokenStorage";
import DashboardLayoutSkeleton from "./components/skeleton/DashboardSkleton";
import AuthLayoutSkeleton from "./components/skeleton/AuthSkelton";
import { useLocation } from "react-router-dom";
import { openMainLayoutRoutes } from "./data/data";

const App = () => {
  const token = getAccessToken();
  const location = useLocation().pathname;

  return (
    <Suspense
      fallback={
        !!token || openMainLayoutRoutes.includes(location) ? (
          <DashboardLayoutSkeleton />
        ) : (
          <AuthLayoutSkeleton />
        )
      }
    >
      <LazyRootRoute />
    </Suspense>
  );
};

export default App;
