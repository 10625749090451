import { createSlice } from "@reduxjs/toolkit";
import {
  addRole,
  addUser,
  assignAlternateRole,
  changePassword,
  deleteRole,
  deleteUser,
  getFeatures,
  getRoles,
  getUsers,
  updateRole,
  updateUser,
  updateUserProfile,
} from "./settingsActions";

export const initialState = {
  isInitialLoad: true,
  rolesInitialLoad: true,
  loading: false,
  addLoading: false,
  formErrors: {},
  features: {},
  users: {},
  roles: {},
};

const settingsSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    setInitialLoad: (state, action) => {
      state.isInitialLoad = action.payload;
    },
    setRoleInitalLoad: (state, action) => {
      state.rolesInitialLoad = action.payload;
    },
    clearSettingsData: (state) => {
      // state.rolesInitialLoad = true;
      state.loading = false;
      state.addLoading = false;
      state.formErrors = false;
      state.users = {};
      state.roles = {};
      state.features = {};
    },
  },
  extraReducers: (builder) => {
    builder
      // user list
      .addCase(getUsers.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUsers.fulfilled, (state, action) => {
        state.loading = false;
        state.isInitialLoad = false;
        state.users = action.payload;
      })
      .addCase(getUsers.rejected, (state) => {
        state.loading = false;
        state.isInitialLoad = false;
        state.users = {};
      })
      // rest of the cases remain same
      .addCase(getRoles.pending, (state) => {
        state.loading = true;
      })
      .addCase(getRoles.fulfilled, (state, action) => {
        state.loading = false;
        state.rolesInitialLoad = false;
        state.roles = action.payload;
      })
      .addCase(getRoles.rejected, (state) => {
        state.loading = false;
        state.rolesInitialLoad = false;
        state.roles = {};
      })
      // add new user
      .addCase(addUser.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(addUser.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(addUser.rejected, (state, action) => {
        state.addLoading = false;
        state.formErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      // update user
      .addCase(updateUser.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(updateUser.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.addLoading = false;
        state.formErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      // delete user
      .addCase(deleteUser.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(deleteUser.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(deleteUser.rejected, (state) => {
        state.addLoading = false;
      })
      // change user password
      .addCase(changePassword.pending, (state) => {
        state.loading = true;
      })
      .addCase(changePassword.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.loading = false;
        state.formErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      // add role
      .addCase(addRole.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(addRole.fulfilled, (state) => {
        state.addLoading = false;
        state.formErrors = {};
      })
      .addCase(addRole.rejected, (state, action) => {
        state.addLoading = false;
        state.formErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      // update role
      .addCase(updateRole.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(updateRole.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(updateRole.rejected, (state, action) => {
        state.addLoading = false;
        state.formErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      })
      // delete role
      .addCase(deleteRole.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(deleteRole.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(deleteRole.rejected, (state) => {
        state.addLoading = false;
      })
      // assign alternate role
      .addCase(assignAlternateRole.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(assignAlternateRole.fulfilled, (state) => {
        state.addLoading = false;
      })
      .addCase(assignAlternateRole.rejected, (state) => {
        state.addLoading = false;
      })
      // get features
      .addCase(getFeatures.pending, (state) => {
        state.loading = true;
      })
      .addCase(getFeatures.fulfilled, (state, action) => {
        state.loading = false;
        state.features = action.payload;
      })
      .addCase(getFeatures.rejected, (state) => {
        state.loading = false;
      })
      // update user profile
      .addCase(updateUserProfile.pending, (state) => {
        state.addLoading = true;
      })
      .addCase(updateUserProfile.fulfilled, (state) => {
        state.addLoading = false;
        state.formErrors = {};
      })
      .addCase(updateUserProfile.rejected, (state, action) => {
        state.addLoading = false;
        state.formErrors =
          typeof action.payload?.message === String
            ? {}
            : action.payload?.message;
      });
  },
});

export const { setInitialLoad, setRoleInitalLoad, clearSettingsData } =
  settingsSlice.actions;
export default settingsSlice.reducer;
