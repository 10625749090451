import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import ThemeConfig from "./config/themeConfig";
import "./index.css";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { SnackbarProvider } from "notistack";
import "./interceptor";
import { persistor, store } from "./redux/store";
import { BrowserRouter } from "react-router-dom";
import { IconButton } from "@mui/material";
import { CloseOutlined } from "@mui/icons-material";
import { closeSnackbar } from "notistack";
import { ErrorBoundary } from "react-error-boundary";
import ErrorFallback from "./components/error-fallback/ErrorFallback";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeConfig>
        <SnackbarProvider
          maxSnack={3}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          autoHideDuration={3000}
          action={(key) => (
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={() => closeSnackbar(key)}
              size="small"
            >
              <CloseOutlined />
            </IconButton>
          )}
        >
          <BrowserRouter>
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onReset={() => {
                window.location.reload();
              }}
              onError={(error, info) => {
                console.error("Error:", error);
                console.error("Component Stack:", info.componentStack);
              }}
            >
              <App />
            </ErrorBoundary>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeConfig>
    </PersistGate>
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
